<template lang="pug">
TableV2(
  :isViewAdd="false"
  :headers="headers"
  :items="item.results"
  :pageCount="item.count"
  :currentPage="item.current"
  :querySearch="getPowersOfAttorney"
  :isLoading="isLoading"
  :actions="actions")
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      headers: [
        { value: 'id', text: 'ID', sortable: false },
        { value: 'created_at', text: this.$t('tableHeaders.created_at'), sortable: false },
        { value: 'number_document', text: this.$t('tableHeaders.number'), sortable: false },
        { value: 'sailor.id', text: this.$t('tableHeaders.sailor_id'), sortable: false },
        { value: 'sailor.sailor_full_name_ukr', text: this.$t('tableHeaders.sailor_full_name'), sortable: false },
        { value: 'sailor.sailor_full_name_eng', text: this.$t('tableHeaders.sailor_full_name_eng'), sortable: false },
        { value: 'manager.id', text: this.$t('tableHeaders.manager_id'), sortable: false },
        { value: 'manager.manager_full_name_ukr', text: this.$t('tableHeaders.manager_full_name'), sortable: false },
        { value: 'manager.manager_full_name_eng', text: this.$t('tableHeaders.manager_full_name_eng'), sortable: false },
        { value: 'status_document', text: this.$t('tableHeaders.status_document'), sortable: false },
        { value: 'event', width: '24px', sortable: false }
      ],
      actions: [
        { id: 1,
          tooltip: 'tooltip.go',
          action: item => this.$router.push(`/back-office/powers-of-attorney/${item.id}`),
          color: 'blue',
          name: 'mdi-arrow-right',
          isView: true }
      ]
    }
  },
  computed: {
    ...mapState({
      item: state => state.backoffice.powersOfAttorney,
      isLoading: state => state.backoffice.isLoading
    })
  },
  methods: {
    ...mapActions(['getPowersOfAttorney'])
  }
}
</script>
